function isScrolledIntoView(el) {
	var rect = el.getBoundingClientRect();
	var elemTop = rect.top;
	var elemBottom = rect.bottom;

	// Only completely visible elements return true:
	var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
	// Partially visible elements return true:
	//isVisible = elemTop < window.innerHeight && elemBottom >= 0;
	return isVisible;
}

document.addEventListener('DOMContentLoaded', function(){

  /*
  * Common
  */
  var telInput = document.querySelector('.js-tel-mask');
  IMask(telInput, {
    mask: '+{7}(000)000-00-00'
  });
  telInput.classList.remove('js-tel-mask');

  var anchorLinks = document.querySelectorAll('.js-anchor-link');
  [].forEach.call(anchorLinks, function(link){
    link.addEventListener('click', function(){
      document.getElementById(link.dataset.target).scrollIntoView({
        behavior: 'smooth'
      });

      var mm = link.closest('.mm');
      if (mm) {
        mm.classList.remove('mm--active');
      }
    });
  });


  var burgerBtn = document.querySelector('.h__burger'),
      mm = document.querySelector('.mm'),
      closeMmBtn = mm.querySelector('.mm__close-btn')
  
  burgerBtn.addEventListener('click', function(){
    mm.classList.add('mm--active');
  });

  closeMmBtn.addEventListener('click', function(){
    mm.classList.remove('mm--active');
  });

  /*
	* init accordions
	*/ 
	document.addEventListener('click', function(e) {
		const acc = e.target.closest('.acc__header');
		if (!acc) {
			return;
		}
		
		acc.classList.toggle('active');
		var panel = acc.nextElementSibling;
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null;
		} else {
			panel.style.maxHeight = panel.scrollHeight + 'px';
		}
	});


  /*
	* Popups
	*/
	// Close popup when click on close btn, or click outside popup
  function closePopup(popup) {

		if (popup.hasAttribute('data-delete')) {
			popup.remove();
		} else {
			popup.classList.remove('popup--active');
			
			var popupError = popup.querySelector('.popup__error');
			if (popupError && popupError.classList.coclosePopupntains('popup__error--active')) {
				popupError.classList.remove('popup__error--active');
			}
		}

		document.body.style.overflow = '';
	}

	document.addEventListener('click', function(e){
		var tg = e.target;

		if (tg.classList.contains('popup')) {
			closePopup(tg);
			return;
		}

		if (tg.classList.contains('popup__close-btn') || tg.hasAttribute('data-close-popup')) {
			var popup = tg.closest('.popup');
			
			closePopup(popup);
		}
	});
	
	document.addEventListener('keyup', function(e){
		if (e.keyCode == 27) {
			var popup = document.querySelector('.popup--active');
			if (popup) {
				closePopup(popup);
			}
		}		
	});

  /*
  * Recall form
  */
  var recallForm = document.forms.recallForm,
      requireElements = recallForm.querySelectorAll('[required]'),
      recallBtn = recallForm.querySelector('.recall-form__submit-btn'),
      recallBtnState = recallBtn.textContent,
      recallValueRange = recallForm.querySelector('.recall-form__range'),
      recallValueInput = recallForm.querySelector('.recall-form__range-value'),
      dateSelect = document.querySelector('.recall-form__date-select'),
      timeSelects = recallForm.querySelectorAll('.recall-form__select--time select'),
      timeInput = recallForm.querySelector('.recall-form__time-input'),
      typeInputs = recallForm.querySelectorAll('.recall-form__type input[name="type"]');

  dateSelect.addEventListener('change', function(){
    var oldTarget = document.querySelector('.recall-form__select--active');
    oldTarget.classList.remove('recall-form__select--active');
    oldTarget.querySelector('select').removeAttribute('required');

    var newTarget = document.getElementById(this.value);
    newTarget.querySelector('select').setAttribute('required', '');
    newTarget.classList.add('recall-form__select--active');
    
    timeInput.value = '';
    document.querySelector('.recall-form__select--active select').value = '';
  });

  timeSelects.forEach(function(select){
    select.addEventListener('change', function(){
      timeInput.value = select.value;

      var event = new Event('change');
			timeInput.dispatchEvent(event);
    });
  });

  // document.querySelector('.recall-form__select--active').querySelector('select').setAttribute('required', '')

  function clearFormErrors() {
    var texts = recallForm.querySelectorAll('.feedback__inline-error:not(.hidden)');
    texts.forEach(function(text){
      text.classList.add('hidden');
    });

    var styles = recallForm.querySelectorAll('.has-error');
    styles.forEach(function(style){
      style.classList.remove('has-error');
    });

    var text = recallForm.querySelector('.recall-form__text-error');
    if (text) {
      text.remove();
    }
  }

  function removeSingleErrorNode(){
    var isErrorNode = this.classList.contains('has-error');

    if (isErrorNode) {
      this.classList.remove('has-error');
      this.closest('.recall-form__line')
        .querySelector('.recall-form__inline-error').classList.add('hidden');
    }
  }

  function scrollToFirstError() {
    var errEl = recallForm.querySelector('.has-error');

    if (errEl && !isScrolledIntoView(errEl)) {
      errEl.closest('.recall-form__line').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  requireElements.forEach(function(el){
    el.addEventListener('input', removeSingleErrorNode);
    el.addEventListener('change', removeSingleErrorNode);
  });

  typeInputs.forEach(function(el){
    el.addEventListener('change', function(){
      var text = recallForm.querySelector('.recall-form__text-error');
      if (text) {
        text.remove();
      }

      var min = 25; 
      if (el.value == '92') {
        min = 40;
      }
      
      recallValueRange.min = min;
      recallValueRange.value = min;
      recallValueInput.value = min;
    });
  });

  recallForm.addEventListener('submit', function(e){
    e.preventDefault();
    
    clearFormErrors();

    var bError = false;

    requireElements.forEach(function(el){
      
      if (!el.checkValidity()) {
        var errorType = el.validity.valueMissing ? 'valueMissing' : 'valueInvalid';
        var errorEl = el.closest('.recall-form__line').querySelector('.recall-form__inline-error');
        if (errorEl) {
          errorEl.innerHTML = el.dataset[errorType];
          errorEl.classList.remove('hidden');
        }
        el.classList.add('has-error');

        bError = true;
      }
    });
    
    if (bError) {
      scrollToFirstError();
      return;
    }

    var request = new XMLHttpRequest();
      request.responseType = 'json';
      request.open(recallForm.getAttribute('method'), recallForm.getAttribute('action'), true);
      // request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');

      request.addEventListener("readystatechange", function (e) {

        if (request.readyState === 4 && request.status === 200) {

          if (!this.response.err) {
            recallBtn.remove();

            var p = document.createElement('p');
            p.classList.add('recall-form__text-success');
            p.textContent = this.response.msg;
            recallForm.querySelector('.recall-form__bottom').appendChild(p);
          } else {
            
            recallBtn.disabled = false;
            recallBtn.textContent = recallBtnState;
          }
        }
      });

      recallBtn.disabled = true;
      recallBtn.textContent = recallBtn.dataset.wait;

      var data = new FormData(recallForm);
      request.send(data);
  });


  // recallValueRange.addEventListener('change', function(){
  //   recallValueInput.value = this.value + ' литров';
  // });


  /*
  * Plus slider
  */
  var plusSliderEl = document.querySelector('.pluses__slider'),
      plusSlider,
      plusSliderInited = false;
  
  function initPlusSlider() {
    plusSlider = new Swiper(plusSliderEl, {
      loop: true,
      navigation: {
        nextEl: plusSliderEl.querySelector('.pluses__btn--next'),
        prevEl: plusSliderEl.querySelector('.pluses__btn--prev'),
      },
    });
    plusSliderInited = true;
  }

  window.addEventListener('resize', function(){
    if ( (window.innerWidth < 768) && !plusSliderInited ) {
      initPlusSlider();
    }

    if ( (window.innerWidth >= 768) && plusSliderInited ) {
      plusSlider.destroy(true, true);
      plusSliderInited = false;
    }
  });

  if (window.innerWidth < 768) {
    initPlusSlider();
  }

  /*
  * cars slider
  */
  var qualitySlider = document.querySelector('.cars__slider');
  new Swiper(qualitySlider, {
    loop: true,
    navigation: {
      nextEl: qualitySlider.querySelector('.cars__btn--next'),
      prevEl: qualitySlider.querySelector('.cars__btn--prev'),
    }
  });


  /*
  * Service slider
  */
//  var servicesSlider = document.querySelector('.services__slider');
//   new Swiper(servicesSlider, {
//     loop: true,
//     navigation: {
//       nextEl: servicesSlider.querySelector('.services__btn--next'),
//       prevEl: servicesSlider.querySelector('.services__btn--prev'),
//     }
//   });


  /*
  * Map
  */
  // var yandexMap = document.querySelector('.map__map'),
	// 		map,
	// 		script = document.createElement('script'),
	// 		prior = document.getElementsByTagName('script')[0];

	// script.async = 1;

	// script.onload = script.onreadystatechange = function( _, isAbort ) {
	// 	if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState) ) {
	// 		script.onload = script.onreadystatechange = null;
	// 		script = undefined;

	// 		if(!isAbort){
	// 			ymaps.ready(function () {

	// 				map && map.destroy();
	// 				map = new ymaps.Map(yandexMap, {
	// 						center: [59.939095, 30.315868],
	// 						zoom: 8,
	// 						controls: ['zoomControl']
	// 				});
	
  //         map.behaviors.disable("scrollZoom");
          
  //         var area1 = new ymaps.GeoObject({
  //           geometry: {
  //             type: "Polygon",
  //             coordinates: [
  //               [
  //                 [59.991101, 30.131664],
  //                 [60.013480, 30.110747],
  //                 [60.038540, 30.111381],
  //                 [60.056609, 30.132615],
  //                 [60.061204, 30.157335],
  //                 [60.084486, 30.203289],
  //                 [60.098257, 30.277133],
  //                 [60.093984, 30.359851],
  //                 [60.084486, 30.379817],
  //                 [60.062471, 30.385522],
  //                 [60.042820, 30.437814],
  //                 [60.017605, 30.460950],
  //                 [59.989513, 30.485353],
  //                 [59.969660, 30.551591],
  //                 [59.921326, 30.527504],
  //                 [59.869734, 30.531308],
  //                 [59.854592, 30.506587],
  //                 [59.849171, 30.465704],
  //                 [59.824925, 30.434962],
  //                 [59.814870, 30.356681],
  //                 [59.809922, 30.329426],
  //                 [59.833700, 30.274914],
  //                 [59.809602, 30.184590],
  //                 [59.798425, 30.160504],
  //                 [59.812795, 30.109796],
  //                 [59.815509, 30.074300],
  //                 [59.860969, 30.065426],
  //                 [59.907162, 30.205191],
  //                 [59.947590, 30.170646],
  //                 [59.977942, 30.204240],
  //                 [59.991101, 30.131664]
  //               ]
  //             ]
  //           }
  //         }, {
  //           fillColor: '#FF0000',
  //           opacity: 0.5,
  //           strokeColor: '#FF0000'
  //         });

  //         var area2 = new ymaps.GeoObject({
  //           geometry: {
  //             type: "Polygon",
  //             coordinates: [
  //               [
  //                 [59.991101, 30.131664],
  //                 [59.993980, 30.009330],
  //                 [60.008266, 29.961158],
  //                 [60.038403, 29.960207],
  //                 [60.088465, 30.101556],
  //                 [60.124222, 30.253997],
  //                 [60.122166, 30.408974],
  //                 [60.049024, 30.548261],
  //                 [60.009132, 30.611501],
  //                 [59.975950, 30.634320],
  //                 [59.943211, 30.602944],
  //                 [59.859310, 30.599141],
  //                 [59.832200, 30.560793],
  //                 [59.805547, 30.539559],
  //                 [59.779990, 30.425149],
  //                 [59.782227, 30.262249],
  //                 [59.774557, 30.135478],
  //                 [59.783346, 30.111075],
  //                 [59.786701, 29.912362],
  //                 [59.874769, 29.963387],
  //                 [59.861542, 30.065438],
  //                 [59.907098, 30.205836],
  //                 [59.947684, 30.171608],
  //                 [59.978671, 30.202667],
  //                 [59.991101, 30.131664]
  //               ]
  //             ]
  //           }
  //         }, {
  //           fillColor: '#22ae22',
  //           opacity: 0.3,
  //           strokeColor: '#22ae22'
  //         });

  //         var area3 = new ymaps.GeoObject({
  //           geometry: {
  //             type: "Polygon",
  //             coordinates: [
  //               [
  //                 [60.082899, 29.203844],
  //                 [60.317900, 29.238629],
  //                 [60.432454, 29.458950],
  //                 [60.432454, 29.458950],
  //                 [60.427541, 30.688033],
  //                 [60.347196, 30.903371],
  //                 [60.209821, 30.946439],
  //                 [60.171055, 31.050795],
  //                 [59.910880, 31.206501],
  //                 [59.713978, 31.161777],
  //                 [59.529372, 31.020979],
  //                 [59.483061, 30.891776],
  //                 [59.423184, 29.917786],
  //                 [59.382465, 29.647085],
  //                 [59.585523, 28.997758],
  //                 [59.717985, 28.954691],
  //                 [59.912368, 28.971255],
  //                 [60.082899, 29.203844]
  //               ]
  //             ]
  //           }
  //         }, {
  //           fillColor: '#f5f52f',
  //           opacity: 0.3,
  //           strokeColor: '#f5f52f'
  //         });

  //         map.geoObjects.add(area3);
  //         map.geoObjects.add(area2);
  //         map.geoObjects.add(area1);
	// 			});
	// 		}
	// 	}
	// }

	// script.src = 'https://api-maps.yandex.ru/2.1/?apikey=64876776-ffaa-4f1c-8865-2639252b1910&lang=ru_RU';
  // prior.parentNode.insertBefore(script, prior);
  

  /*
  * App slider
  */
  // var appSlider = document.querySelector('.app__slider');
  // new Swiper(appSlider, {
  //   loop: true,
  //   navigation: {
  //     nextEl: appSlider.querySelector('.app__btn--next'),
  //     prevEl: appSlider.querySelector('.app__btn--prev'),
  //   }
  // });

  /*
  * Review slider
  */
  // var reviewsSlider = document.querySelector('.reviews__slider');
  // new Swiper(reviewsSlider, {
  //   loop: true,
  //   slidesPerView: 1,
  //   navigation: {
  //     nextEl: reviewsSlider.querySelector('.reviews__btn--next'),
  //     prevEl: reviewsSlider.querySelector('.reviews__btn--prev'),
  //   },
  //   breakpoints: {
  //     500: {
  //       slidesPerView: 2,
  //       spaceBetween: 20
  //     },
  //     768 : {
  //       slidesPerView: 2,
  //     },
  //     992: {
  //       slidesPerView: 3,
  //     },
  //     1100: {
  //       slidesPerView: 4
  //     },
  //     1280: {
  //       slidesPerView: 5
  //     }
  //   }
  // });
});


/*
* Feedback form
*/
document.addEventListener('DOMContentLoaded', function(){
  var feedbackForm = document.querySelector('.feedback__form');

  var requireElements = feedbackForm.querySelectorAll('[required]'),
    submitBtn = feedbackForm.querySelector('.feedback__submit'),
    state = submitBtn.textContent;

  function clearFormErrors() {
    var texts = feedbackForm.querySelectorAll('.feedback__inline-error:not(.hidden)');
    texts.forEach(function(text){
      text.classList.add('hidden');
    });

    var styles = feedbackForm.querySelectorAll('.has-error');
    styles.forEach(function(style){
      style.classList.remove('has-error');
    });
  }

  function removeSingleErrorNode(){
    var isErrorNode = this.classList.contains('has-error');

    if (isErrorNode) {
      this.classList.remove('has-error');
      this.closest('.feedback__form-item')
        .querySelector('.feedback__inline-error').classList.add('hidden');
    }
  }

  function scrollToFirstError() {
    var errEl = feedbackForm.querySelector('.has-error');

    if (errEl) {
      errEl.closest('.feedback__form-item').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  requireElements.forEach(function(el){
    el.addEventListener('input', removeSingleErrorNode);
    el.addEventListener('change', removeSingleErrorNode);
  });

  feedbackForm.addEventListener('submit', function(e){
    e.preventDefault();

    clearFormErrors();

    var bError = false;

    requireElements.forEach(function(el){
      
      if (!el.checkValidity()) {
        var errorType = el.validity.valueMissing ? 'valueMissing' : 'valueInvalid';
        var errorEl = el.closest('.feedback__form-item').querySelector('.feedback__inline-error');
        if (errorEl) {
          errorEl.innerHTML = el.dataset[errorType];
          errorEl.classList.remove('hidden');
        }
        el.classList.add('has-error');

        bError = true;
      }
    });
    
    if (bError) {
      scrollToFirstError();
      return;
    }

    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open(feedbackForm.getAttribute('method'), feedbackForm.getAttribute('action'), true);
    // request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');

    request.addEventListener("readystatechange", function (e) {

      if (request.readyState === 4 && request.status === 200) {

        if (!this.response.err) {
          var sucessEls = document.querySelectorAll('.success-hide');
          sucessEls.forEach(function(el){
            el.remove();
          })

          var p = document.createElement('p');
          p.classList.add('feedback__text-success');
          p.textContent = this.response.msg;
          feedbackForm.appendChild(p);
        } else {

          this.response.elements.forEach(function(el){
            var errEl = document.querySelector('[name="'+el.errName+'"]');
            if (errEl) {
              errEl.classList.add('has-error');
              var errText = errEl.closest('.feedback__form-item').querySelector('.feedback__inline-error');
              errText.textContent = el.msg;
              errText.classList.remove('hidden');
            }
          });

          scrollToFirstError();
          submitBtn.disabled = false;
        }

        submitBtn.textContent = state;
      }
    });

    submitBtn.disabled = true;
    submitBtn.textContent = submitBtn.dataset.wait;

    var data = new FormData(feedbackForm);
    request.send(data);
  });

})